@mixin preloader-mobile {
    &-progress {
        font-size: 3rem;
    }
}

@mixin preloader-desktop {
    &-progress {
        font-size: 1.125rem;
    }
}

.preloader {
    @media only screen and (min-width: 100px) {
        @media only screen and (max-width: 480px) {
            @include preloader-mobile;
        }
    }
    @media only screen and (min-width: 481px) {
        @include preloader-desktop;
    }
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(-105deg, #171717, #000000);
    z-index: 1000;
    &-progress {
        position: fixed;
        bottom: 2.5rem;
        left: 2.5rem;
        color: white;
    }
}
