@mixin video-mobile {
    &-container {
        &--big {
            display: none;
        }
    }
    &-overlap {
        width: 100% !important;
        height: 121% !important;
    }
}

.video {
    @media only screen and (min-width: 100px) {
        @media only screen and (max-width: 480px) {
            @include video-mobile;
        }
    }
    &-overlap {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        height: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
    &-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
