$mobile-small: 360px;
$break-small: 767px;
$break-large: 768px;
$break-large-right: 1024px;
//$break-large-right:1269px;
$break-large-1366: 1366px;
@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (min-width: 280px) and (max-width: $break-small) {
      @content;
    }
  } @else if $media == mobileLandscape {
    @media only screen and (max-height: 425px) and (max-width: 896px) and (orientation: landscape) {
      @content;
    }
  } @else if $media == mobileSmall {
    @media only screen and (min-width: 280px) and (max-width: 375px) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-large) and (max-width: $break-large-right) {
      @content;
    }
  } @else if $media == tablet-portrait {
    @media only screen and (min-width: $break-large) and (max-width: $break-large-right) and (orientation: portrait) {
      @content;
    }
  } @else if $media == device {
    @media only screen and (min-width: 280px) and (max-width: $break-large-right) {
      @content;
    }
  } @else if $media == descMini {
    @media only screen and (min-width: 820px) and (max-width: 1200px) {
      @content;
    }
  } @else if $media == desk-from-1620 {
    @media only screen and (min-width: 1620px) {
      @content
    }
  }
}

//
//// Mixins.
//
//@mixin nl {
//  margin: 0;
//  padding: 0;
//  text-indent: 0;
//  list-style: none;
//  list-style-position: outside;
//}
//
@mixin cf {
  &:before,
  &:after {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }

  .lt-ie8 & {
    zoom: 1;
  }
}

//
//@mixin blocks-justify {
//  text-align: justify;
//  text-justify: newspaper;
//}
//
//// ADDS A BROWSER PREFIX TO THE PROPERTY
//@mixin css3-prefix($property, $value) {
//  -webkit-#{$property}: #{$value};
//  -khtml-#{$property}: #{$value};
//  -moz-#{$property}: #{$value};
//  -ms-#{$property}: #{$value};
//  -o-#{$property}: #{$value};
//  #{$property}: #{$value};
//}
//
//// BACKGROUND GRADIENT
//@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
//  background-color: $startColor;
//  background-images: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
//  background-images: -webkit-linear-gradient(top, $startColor, $endColor);
//  background-images: -moz-linear-gradient(top, $startColor, $endColor);
//  background-images: -ms-linear-gradient(top, $startColor, $endColor);
//  background-images: -o-linear-gradient(top, $startColor, $endColor);
//  background-images: linear-gradient(top, $startColor, $endColor);
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
//}
//
//// BACKGROUND HORIZONTAL
//@mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
//  background-color: $startColor;
//  background-images: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
//  background-images: -webkit-linear-gradient(left, $startColor, $endColor);
//  background-images: -moz-linear-gradient(left, $startColor, $endColor);
//  background-images: -ms-linear-gradient(left, $startColor, $endColor);
//  background-images: -o-linear-gradient(left, $startColor, $endColor);
//  background-images: linear-gradient(left, $startColor, $endColor);
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
//}
//
//// BACKGROUND RADIAL
//@mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos: 100%) {
//  background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
//  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos, $startColor), color-stop($endPos, $endColor));
//  background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
//  background: -o-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
//  background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
//  background: radial-gradient(ellipse at center, $startColor $startPos, $endColor $endPos);
//}
//
//// BACKGROUND SIZE
//@mixin background-size($width: 100%, $height: $width) {
//  @if type-of($width) == 'number' and $height != null {
//    @include css3-prefix('background-size', $width $height);
//  } @else {
//    @include css3-prefix('background-size', $width);
//  }
//
//}
//
//// BACKGROUND COLOR OPACITY
//@mixin background-opacity($color: #000, $opacity: 0.85) {
//  background: $color;
//  background: rgba($color, $opacity);
//}
//
//// BORDER RADIUS
//@mixin border-radius($radius: 5px) {
//  @include css3-prefix('border-radius', $radius);
//}
//
//@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
//  -webkit-border-top-left-radius: $topLeftRadius;
//  -webkit-border-top-right-radius: $topRightRadius;
//  -webkit-border-bottom-right-radius: $bottomRightRadius;
//  -webkit-border-bottom-left-radius: $bottomLeftRadius;
//
//  -moz-border-radius-topleft: $topLeftRadius;
//  -moz-border-radius-topright: $topRightRadius;
//  -moz-border-radius-bottomright: $bottomRightRadius;
//  -moz-border-radius-bottomleft: $bottomLeftRadius;
//
//  border-top-left-radius: $topLeftRadius;
//  border-top-right-radius: $topRightRadius;
//  border-bottom-right-radius: $bottomRightRadius;
//  border-bottom-left-radius: $bottomLeftRadius;
//}
//
//// BOX
//@mixin box($orient: horizontal, $pack: center, $align: center) {
//  display: -webkit-box;
//  display: -moz-box;
//  display: box;
//
//  @include css3-prefix('box-orient', $orient);
//  @include css3-prefix('box-pack', $pack);
//  @include css3-prefix('box-align', $align);
//}
//
//// BOX RGBA
//@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
//  background-color: transparent;
//  background-color: rgba($r, $g, $b, $opacity);
//  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}', endColorstr='#{$color}');
//  zoom: 1;
//}
//
//// BOX SHADOW
//@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4), $inset: "") {
//  @if ($inset != "") {
//    @include css3-prefix('box-shadow', $inset $x $y $blur $color)
//  } @else {
//    @include css3-prefix('box-shadow', $x $y $blur $color);
//  }
//
//}
//
//// BOX SIZING
//@mixin box-sizing($type: border-box) {
//  @include css3-prefix('box-sizing', $type);
//}
//
//// COLUMNS
//@mixin columns($count: 3, $gap: 10) {
//  @include css3-prefix('column-count', $count);
//  @include css3-prefix('column-gap', $gap);
//}
//
//// DOUBLE BORDERS
//@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
//  border: 1px solid $colorOne;
//
//  @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo);
//
//  @include border-radius($radius);
//}
//
//// FLEX
//@mixin flex($value: 1) {
//  @include css3-prefix('box-flex', $value);
//}
//
//// FLIP
//@mixin flip($scaleX: -1) {
//  @include css3-prefix('transform', scaleX($scaleX));
//  filter: "FlipH";
//  -ms-filter: "FlipH";
//}
//
//// FONT FACE
//@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
//  font-family: $fontFamily;
//  src: url($eotFileSrc) format('eot'), url($woffFileSrc) format('woff'), url($ttfFileSrc) format('truetype'), url($svgFileSrc + $svgFontID) format('svg');
//
//}
//
//// OPACITY
//@mixin opacity($opacity: 0.5) {
//  $opacityMultiplied: ($opacity * 100);
//
//  filter: alpha(opacity=$opacityMultiplied);
//  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
//  @include css3-prefix('opacity', $opacity);
//}
//
//// OUTLINE RADIUS
//@mixin outline-radius($radius: 5px) {
//  @include css3-prefix('outline-radius', $radius);
//}
//
//// RESIZE
//@mixin resize($direction: both) {
//  @include css3-prefix('resize', $direction);
//}
//
//// ROTATE
//@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
//  @include css3-prefix('transform', rotate($deg + deg));
//  filter: progid:DXImageTransform.Microsoft.Matrix(M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
//  zoom: 1;
//}
//
//// PLACEHOLDER
//@mixin placeholder-color($color) {
//
//  &:-moz-placeholder {
//    color: $color
//  }
//
//  &::-webkit-input-placeholder {
//    color: $color
//  }
//  &.placeholder {
//    color: $color
//  }
//
//}
//
//@mixin placeholder {
//  &::-webkit-input-placeholder {
//    @content
//  }
//
//  &:-moz-placeholder {
//    @content
//  }
//
//  &::-moz-placeholder {
//    @content
//  }
//
//  &:-ms-input-placeholder {
//    @content
//  }
//
//}
//
//// TEXT SHADOW
//@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
//  text-shadow: $x $y $blur $color;
//}
//
//// TRANSFORM
//@mixin transform($params) {
//  @include css3-prefix('transform', $params);
//}
//
//// TRANSFORM-ORIGIN
//@mixin transform-origin($params) {
//  @include css3-prefix('transform-origin', $params);
//}
//
//// TRANSFORM STYLE
//@mixin transform-style($style: preserve-3d) {
//  @include css3-prefix('transform-style', $style)
//}
//
//// TRANSITION
//@mixin transition($properties...) {
//  @if length($properties) >= 1 {
//    @include css3-prefix('transition', $properties);
//  } @else {
//    @include css3-prefix('transition', "all 0.2s ease-in-out 0s");
//  }
//
//}
//
//// KEYFRAMES
//@mixin keyframes($animation-name) {
//  @-webkit-keyframes #{$animation-name}{
//    @content
//  }
//
//  @-moz-keyframes #{$animation-name}{
//    @content
//  }
//
//  @-ms-keyframes #{$animation-name}{
//    @content
//  }
//
//  @-o-keyframes #{$animation-name}{
//    @content
//  }
//
//  @keyframes #{$animation-name}{
//    @content
//  }
//}
//
//// ANIMATION
//@mixin animation($str) {
//  @include css3-prefix('animation', $str);
//}
//
//@mixin font_face($file_name, $font_name:$file_name, $path:$font_path, $weight:normal, $style:normal) {
//  @font-face {
//    font-family: quote($font_name);
//    src: url($path + $file-name + ".woff2") format("woff2"),
//    url($path + $file-name + ".woff") format("woff");
//    font-weight: $weight;
//    font-style: $style;
//  }
//}
//
$browser-context: 16;
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

//
//@mixin font_sizes($size, $line_height) {
//  font-size: em($size);
//  line-height: em($line_height, $size);
//}
//
//@mixin delay($rule, $number, $value) {
//  @for $i from 1 to ($number + 1) {
//    &:nth-child(#{$i}) {
//      -webkit-#{$rule}-delay: (#{$i*$value});
//      #{$rule}-delay: (#{$i*$value});
//    }
//  }
//}
//
//@mixin filter($filter-type,$filter-amount) {
//  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
//  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
//  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
//  -o-filter: $filter-type+unquote('(#{$filter-amount})');
//  filter: $filter-type+unquote('(#{$filter-amount})');
//}

//@include delay(animation, 8, 0.25s);

//@for $i from 1 to 10 {
//	.selector:nth-child(#{$i}) { transition-delay: $i * 0.25s + .325s; }
//}