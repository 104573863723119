@mixin opengl-mobile {
    &--panorama {
        div {
            width: 100%;
        }
    }
    &--world {
        top: -5%;
    }
}

@mixin opengl-desktop {
    &--panorama {
        canvas {
            //transform: translateX(20%);
        }
    }
}

.openglWrapper {
    @media only screen and (min-width: 100px) {
        @media only screen and (max-width: 480px) {
            @include opengl-mobile;
        }
    }
    @media only screen and (min-width: 481px) {
        @include opengl-desktop;
    }
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    div {
        width: 100%;
    }
    &--panorama {
        display: flex;
        justify-content: end;
        align-items: center;
    }
    &--logo {
        height: 100%;
        div {
            height: 100%;
        }
    }
}

@media only screen and (min-width: 481px) {
    #slide-7 {
        z-index: 100;
        .container {
            height: 100% !important;
        }
    }
}

@media only screen and (min-width: 100px) {
    @media only screen and (max-width: 480px) {
        #slide-7 {
            height: 140vh !important;
            .container {
                height: 100% !important;
            }
        }
    }
}
