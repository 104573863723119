/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 31, 2022 */

@font-face {
    font-family: 'bodoni_72book';
    src: url('../assets/fonts/bodoni_72-webfont.eot');
    src: url('../assets/fonts/bodoni_72-webfont.eot?#iefix')
            format('embedded-opentype'),
        //url('../assets/fonts/bodoni_72-webfont.woff2') format('woff2'),
        url('../assets/fonts/bodoni_72-webfont.woff') format('woff'),
        url('../assets/fonts/bodoni_72-webfont.ttf') format('truetype'),
        url('../assets/fonts/bodoni_72-webfont.svg#bodoni_72book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'edu_favorit_monomedium';
    src: url('../assets/fonts/edufavoritmono-medium-webfont.eot');
    src: url('../assets/fonts/edufavoritmono-medium-webfont.eot?#iefix')
            format('embedded-opentype'),
        //url('../assets/fonts/edufavoritmono-medium-webfont.woff2') format('woff2'),
        url('../assets/fonts/edufavoritmono-medium-webfont.woff') format('woff'),
        url('../assets/fonts/edufavoritmono-medium-webfont.ttf')
            format('truetype'),
        url('../assets/fonts/edufavoritmono-medium-webfont.svg#edu_favorit_monomedium')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeue.ttc') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueBold';
    src: url('../assets/fonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TimesNewRoman';
    src: url('../assets/fonts/TimesNewRoman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
