@mixin wallet-mobile {
    font-size: 3.5rem !important;
    font-weight: 300;
    border: none !important;
    left: auto;
    right: 6.25%!important;
    //right: 50%;
    //opacity: 1 !important;
    .theme_light & {
        border: 1px solid #000;
        color: #000;
        &:hover {
            background: #000;
            color: #fff;
        }
    }
}

.wallet {
    @media only screen and (min-width: 100px) {
        @media only screen and (max-width: 480px) {
            @include wallet-mobile;
        }
    }
    &-text-container {
        transition-duration: 1s;
    }
    span {
        margin-left: rem(10);
        &:first-child {
            margin-left: 0;
        }
    }
}
